.experts-section {
	padding: 2rem;
	background: #f9fafb;
	min-height: 100vh;
	max-width: 1440px;
	margin: 0 auto;
}

.experts-section h2 {
	font-size: 2rem;
	font-weight: 700;
	color: #111827;
	margin-bottom: 2.5rem;
	letter-spacing: -0.025em;
	padding-left: 0.5rem;
}

.experts-grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
	gap: 2rem;
	align-items: stretch;
}

.expert-card {
	display: flex;
	flex-direction: column;
	background: white;
	border-radius: 20px;
	padding: 2rem;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 10px 15px -3px rgba(0, 0, 0, 0.05);
	transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
	border: 1px solid #e5e7eb;
	position: relative;
	overflow: hidden;
	height: 100%;
}

.expert-card::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 4px;
	background: linear-gradient(90deg, #f49e53, #ffb06c);
	opacity: 0;
	transition: opacity 0.3s ease;
}

.expert-card:hover::after {
	opacity: 1;
}

.expert-card:hover {
	transform: translateY(-4px);
	box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.expert-header {
	margin-bottom: 2rem;
	flex-shrink: 0;
}

.expert-header-content {
	display: flex;
	align-items: center;
	gap: 1.5rem;
}

.expert-image-container {
	width: 80px;
	height: 80px;
	border-radius: 50%;
	overflow: hidden;
	border: 3px solid #f3f4f6;
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
	flex-shrink: 0;
	background: #f3f4f6;
}

.expert-avatar {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.expert-title {
	flex: 1;
	min-width: 0;
}

.expert-title h3 {
	font-size: 1.5rem;
	font-weight: 700;
	color: #111827;
	margin: 0 0 0.75rem 0;
	letter-spacing: -0.025em;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.status-badge {
	display: inline-flex;
	align-items: center;
	padding: 0.375rem 1rem;
	border-radius: 9999px;
	font-size: 0.875rem;
	font-weight: 600;
	letter-spacing: 0.025em;
}

.status-badge.active {
	background: #dcfce7;
	color: #059669;
}

.status-badge.inactive {
	background: #fee2e2;
	color: #dc2626;
}

.expert-info {
	display: flex;
	flex-direction: column;
	gap: 1.75rem;
	flex-grow: 1;
}

.info-group {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1.5rem;
	align-items: start;
}

.info-item {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.info-item.full-width {
	grid-column: 1 / -1;
}

.info-label {
	font-size: 0.75rem;
	font-weight: 600;
	color: #6b7280;
	text-transform: uppercase;
	letter-spacing: 0.05em;
}

.info-value {
	font-size: 0.9375rem;
	color: #111827;
	line-height: 1.5;
	word-break: break-word;
}

.info-value.highlight {
	color: #059669;
	font-weight: 600;
}

.capitalize {
	text-transform: capitalize;
}

.tags-container {
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
	margin: -0.25rem;
}

.tag {
	background: #f3f4f6;
	color: #374151;
	padding: 0.375rem 0.875rem;
	border-radius: 9999px;
	font-size: 0.8125rem;
	font-weight: 500;
	margin: 0.25rem;
	transition: all 0.2s ease;
}

.tag:hover {
	background: #e5e7eb;
	transform: translateY(-1px);
}

.link-value {
	color: #2563eb;
	text-decoration: none;
	word-break: break-word;
	font-size: 0.9375rem;
	transition: color 0.2s ease;
	display: inline-block;
	max-width: 100%;
}

.link-value:hover {
	color: #1d4ed8;
	text-decoration: underline;
}

.expert-actions {
	margin-top: 2rem;
	display: flex;
	justify-content: flex-end;
	gap: 1rem;
	flex-shrink: 0;
	border-top: 1px solid #e5e7eb;
	padding-top: 1.5rem;
}

.status-toggle {
	padding: 0.75rem 1.75rem;
	border-radius: 12px;
	border: none;
	font-weight: 600;
	font-size: 0.875rem;
	cursor: pointer;
	transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
	display: inline-flex;
	align-items: center;
	justify-content: center;
	gap: 0.625rem;
}

.status-toggle.activate {
	background: linear-gradient(135deg, #059669, #047857);
	color: white;
	box-shadow: 0 2px 4px rgba(5, 150, 105, 0.2);
}

.status-toggle.activate:hover {
	background: linear-gradient(135deg, #047857, #065f46);
	transform: translateY(-2px);
	box-shadow: 0 4px 12px rgba(5, 150, 105, 0.3);
}

.status-toggle.deactivate {
	background: linear-gradient(135deg, #dc2626, #b91c1c);
	color: white;
	box-shadow: 0 2px 4px rgba(220, 38, 38, 0.2);
}

.status-toggle.deactivate:hover {
	background: linear-gradient(135deg, #b91c1c, #991b1b);
	transform: translateY(-2px);
	box-shadow: 0 4px 12px rgba(220, 38, 38, 0.3);
}

@media (max-width: 1280px) {
	.experts-grid {
		grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
	}
}

@media (max-width: 768px) {
	.experts-section {
		padding: 1.5rem;
	}

	.experts-section h2 {
		font-size: 1.75rem;
		margin-bottom: 2rem;
	}

	.experts-grid {
		grid-template-columns: 1fr;
		gap: 1.5rem;
	}

	.expert-card {
		padding: 1.5rem;
	}

	.expert-header-content {
		gap: 1rem;
	}

	.expert-image-container {
		width: 64px;
		height: 64px;
	}

	.expert-title h3 {
		font-size: 1.25rem;
	}
}

@media (max-width: 480px) {
	.experts-section {
		padding: 1rem;
	}

	.expert-card {
		padding: 1.25rem;
	}

	.info-group {
		grid-template-columns: 1fr;
		gap: 1.25rem;
	}

	.expert-actions {
		flex-direction: column;
	}

	.status-toggle {
		width: 100%;
	}
}
