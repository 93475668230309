.overview {
	width: 100%;
}

.stats-cards {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
	gap: 1.5rem;
	margin-bottom: 2rem;
}

.stat-card {
	background: white;
	padding: 1.5rem;
	border-radius: 16px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
	display: flex;
	align-items: center;
	gap: 1.5rem;
	transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.stat-card:hover {
	transform: translateY(-2px);
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.stat-icon-wrapper {
	width: 48px;
	height: 48px;
	border-radius: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #eef2ff;
	color: #4f46e5;
}

.stat-icon-wrapper.new {
	background: #ecfdf5;
	color: #059669;
}

.stat-icon-wrapper.messages {
	background: #eff6ff;
	color: #3b82f6;
}

.stat-icon {
	width: 24px;
	height: 24px;
}

.stat-info h3 {
	color: #6b7280;
	font-size: 0.875rem;
	font-weight: 500;
	margin: 0 0 0.5rem 0;
}

.stat-info p {
	color: #111827;
	font-size: 1.5rem;
	font-weight: 600;
	margin: 0;
}

.charts-section {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	gap: 2rem;
	width: 100%;
}

.chart-container {
	background: white;
	padding: 2rem;
	border-radius: 16px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
	width: 100%;
	min-width: 0;
}

.chart-container h3 {
	color: #111827;
	font-size: 1.125rem;
	font-weight: 600;
	margin-bottom: 1.5rem;
	text-align: center;
}

.loading-container {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 400px;
}

.error-message {
	text-align: center;
	color: #dc2626;
	padding: 2rem;
	background: #fef2f2;
	border-radius: 12px;
	margin: 1rem;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@media (max-width: 768px) {
	.overview {
		padding: 1rem;
	}

	.charts-section {
		grid-template-columns: 1fr;
	}
}

.friendship-stat .category-info {
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
}

.friendship-stat .category-info p {
	color: #1f2937;
	font-size: 1.25rem;
	font-weight: 600;
	margin: 0;
}

.friendship-stat .category-info small {
	color: #6b7280;
	font-size: 0.875rem;
}

.categories-list {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin-top: 1rem;
}

.category-item {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.category-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.category-name {
	font-weight: 500;
	color: #1f2937;
}

.category-percentage {
	font-weight: 600;
	color: #4f46e5;
}

.category-bar-container {
	width: 100%;
	height: 8px;
	background: #f3f4f6;
	border-radius: 4px;
	overflow: hidden;
}

.category-bar {
	height: 100%;
	border-radius: 4px;
	transition: width 0.3s ease;
}

.category-issue {
	font-size: 0.875rem;
	color: #6b7280;
}

.charts-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
	gap: 2rem;
	margin-top: 2rem;
}

.chart-container {
	background: white;
	padding: 1.5rem;
	border-radius: 16px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.chart-container h3 {
	color: #111827;
	font-size: 1.125rem;
	font-weight: 600;
	margin-bottom: 1.5rem;
	text-align: center;
}

.recharts-tooltip-wrapper {
	background: white;
	border-radius: 8px;
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.recharts-default-tooltip {
	background-color: white !important;
	border: none !important;
	border-radius: 8px !important;
	padding: 8px 12px !important;
}

.recharts-pie-label-text {
	font-size: 12px;
	fill: #4b5563;
}

/* Add these new styles for better bar chart readability */
.recharts-cartesian-grid-horizontal line,
.recharts-cartesian-grid-vertical line {
	stroke: #f3f4f6;
}

.recharts-yAxis .recharts-text {
	fill: #4b5563;
	font-size: 12px;
}

.recharts-xAxis .recharts-text {
	fill: #4b5563;
	font-size: 12px;
}
