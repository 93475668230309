/* Modal Overlay */
.modal-overlay {
	position: fixed;
	inset: 0;
	background: rgba(0, 0, 0, 0.4);
	backdrop-filter: blur(8px);
	display: grid;
	place-items: center;
	z-index: 1000;
	padding: 1.5rem;
}

/* Modal Container */
.activation-modal {
	background: white;
	border-radius: 24px;
	width: 100%;
	max-width: 480px;
	box-shadow: 
		0 4px 6px -1px rgba(0, 0, 0, 0.1),
		0 2px 4px -1px rgba(0, 0, 0, 0.06),
		0 20px 25px -5px rgba(0, 0, 0, 0.08);
	position: relative;
	overflow: hidden;
	transform: translateY(0);
	transition: transform 0.3s ease;
}

/* Modal Header */
.modal-header {
	padding: 1.75rem 2rem;
	border-bottom: 1px solid #f3f4f6;
	position: relative;
}

.modal-header h2 {
	font-size: 1.25rem;
	font-weight: 600;
	color: #111827;
	margin: 0;
	line-height: 1.4;
}

/* Close Button */
.close-button {
	position: absolute;
	top: 1.5rem;
	right: 1.5rem;
	width: 2rem;
	height: 2rem;
	border-radius: 50%;
	border: none;
	background: transparent;
	color: #6b7280;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: all 0.2s ease;
	padding: 0;
}

.close-button:hover {
	background: #f3f4f6;
	color: #111827;
	transform: rotate(90deg);
}

.close-button svg {
	width: 1.25rem;
	height: 1.25rem;
}

/* Expert Name Section */
.expert-name {
	padding: 1rem 2rem;
	background: #f9fafb;
	color: #4b5563;
	font-size: 0.9375rem;
	border-bottom: 1px solid #f3f4f6;
}

.expert-name strong {
	color: #111827;
	font-weight: 500;
}

/* Form Container */
.form-container {
	padding: 2rem;
}

/* Form Section */
.form-section {
	margin-bottom: 1.5rem;
}

.form-section-header {
	margin-bottom: 1rem;
}

.form-section-header h3 {
	font-size: 0.875rem;
	font-weight: 600;
	color: #6b7280;
	text-transform: uppercase;
	letter-spacing: 0.05em;
}

/* Radio Group */
.radio-group {
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
}

.radio-option {
	position: relative;
	display: flex;
	align-items: center;
	padding: 0.875rem 1rem;
	background: white;
	border: 1px solid #e5e7eb;
	border-radius: 12px;
	cursor: pointer;
	transition: all 0.2s ease;
	user-select: none;
}

.radio-option:hover {
	border-color: #f49e53;
	background: #fff8f3;
}

.radio-option.selected {
	border-color: #f49e53;
	background: #fff8f3;
}

.radio-input {
	appearance: none;
	width: 1.125rem;
	height: 1.125rem;
	border: 2px solid #d1d5db;
	border-radius: 50%;
	margin: 0;
	margin-right: 0.75rem;
	padding: 0;
	transition: all 0.2s ease;
}

.radio-input:checked {
	border-color: #f49e53;
	border-width: 4px;
	background: white;
}

.radio-label {
	font-size: 0.9375rem;
	color: #374151;
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

/* Form Group */
.form-group {
	margin-bottom: 1.5rem;
}

.form-label {
	display: block;
	font-size: 0.875rem;
	font-weight: 500;
	color: #4b5563;
	margin-bottom: 0.5rem;
}

.form-label.required::after {
	content: '*';
	color: #ef4444;
	margin-left: 0.25rem;
}

/* Form Controls */
.form-select,
.form-input {
	width: 100%;
	padding: 0.75rem 1rem;
	border: 1px solid #e5e7eb;
	border-radius: 10px;
	background: white;
	font-size: 0.9375rem;
	color: #111827;
	transition: all 0.2s ease;
	outline: none;
}

.form-select {
	appearance: none;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236B7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: right 1rem center;
	background-size: 1rem;
	padding-right: 2.5rem;
}

.form-select:hover,
.form-input:hover {
	border-color: #f49e53;
}

.form-select:focus,
.form-input:focus {
	border-color: #f49e53;
	box-shadow: 0 0 0 3px rgba(244, 158, 83, 0.1);
}

/* Helper Text */
.helper-text {
	margin-top: 0.5rem;
	font-size: 0.8125rem;
	color: #6b7280;
}

/* Modal Actions */
.modal-actions {
	padding: 1.5rem 2rem;
	display: flex;
	justify-content: flex-end;
	gap: 1rem;
	border-top: 1px solid #f3f4f6;
	background: #f9fafb;
}

/* Buttons */
.btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 0.625rem 1.25rem;
	border-radius: 8px;
	font-size: 0.875rem;
	font-weight: 500;
	transition: all 0.2s ease;
	cursor: pointer;
	white-space: nowrap;
}

.btn-secondary {
	background: white;
	color: #4b5563;
	border: 1px solid #e5e7eb;
}

.btn-secondary:hover {
	background: #f9fafb;
	border-color: #d1d5db;
}

.btn-primary {
	background: #f49e53;
	color: white;
	border: none;
}

.btn-primary:hover {
	background: #f28c3c;
	transform: translateY(-1px);
	box-shadow: 0 4px 6px -1px rgba(244, 158, 83, 0.2);
}

.btn:active {
	transform: translateY(1px);
}

/* Responsive Styles */
@media (max-width: 640px) {
	.modal-overlay {
		padding: 1rem;
	}

	.activation-modal {
		border-radius: 16px;
	}

	.modal-header,
	.expert-name,
	.form-container,
	.modal-actions {
		padding: 1.25rem;
	}

	.modal-actions {
		flex-direction: column-reverse;
		gap: 0.75rem;
	}

	.btn {
		width: 100%;
		padding: 0.75rem;
	}

	.radio-option {
		padding: 0.75rem;
	}
}
