@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

#root {
	overflow-x: hidden;
}
body {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'Poppins';
}

*,
*:before,
*:after {
	box-sizing: border-box;
}
:root {
	--primary-gradient: linear-gradient(to right, #ffb06c, #f49e53);
	--foreground: #fae6c0;
}
.div-main {
	height: 100dvh !important;
	max-height: 100dvh !important;
}
.headline {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 32px;
}
.headline h2 {
	text-align: center;
	font-weight: bold;
	line-height: 1.1;
	color: #140c01;
	font-size: 64px;
	margin: 0px;
}
.headline p {
	text-align: center;
	font-size: 16px;
	font-family: 'Open sans';
	color: #140c01;
	margin: 0px;
	max-width: 364px;
}
@media (max-width: 768px) {
	.headline h2 {
		font-size: 48px;
	}
	.headline p {
		font-size: 16px;
	}
}
@media (max-width: 480px) {
	.headline h2 {
		font-size: 32px;
	}
	.headline p {
		font-size: 14px;
	}
}
.px-section-break {
	height: 128px;
	width: 100%;
	background: var(--foreground);
}
.px-section-break-secondary {
	height: 128px;
	width: 100%;
	background: #fffdf8;
	position: relative;
}

.px-section-break-secondary::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url('./assets/Background.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	opacity: 0.4;
}

.spinner-container {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100dvh;
	background-color: var(--foreground);
}

.spinner {
	width: 50px;
	height: 50px;
	border: 5px solid #f3f3f3;
	border-top: 5px solid #f49e53;
	border-radius: 50%;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
