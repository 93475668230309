.expert-modal-overlay {
	position: fixed;
	width: 100vw !important;
	height: 100vh !important;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.75);
	backdrop-filter: blur(4px);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 9998;
	animation: fadeIn 0.2s ease-out;
	padding: clamp(0px, 2vw, 20px);
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	margin: 0px;
}

.expert-modal {
	background: white;
	border-radius: 14px;
	overflow-y: auto;
	position: relative;
	animation: slideUp 0.3s ease-out;
	z-index: 9999;
	margin: auto;
	padding: 24px;
	overflow-x: hidden;
	width: 100%;
	max-width: 1024px;
}

.expert__card {
	width: 100%;
	max-width: min(100%, 1024px);
	margin: 0 auto;
	padding: 24px;
	display: flex;
	flex-direction: column;
	background: #fff;
	border-radius: 16px;
	border: 1px solid #eaeaea;
	user-select: none;
	touch-action: pan-y pinch-zoom;
	cursor: grab;
	transition: transform 0.3s ease;
	will-change: transform;
}

.expert__card.dragging {
	cursor: grabbing;
	transition: none;
}

.progress-dot {
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: rgba(255, 255, 255, 0.5);
	transition: all 0.3s ease;
}

.progress-dot.active {
	background: white;
	transform: scale(1.2);
}

.slide-left {
	animation: slideLeft 0.3s ease-out forwards;
}

.slide-right {
	animation: slideRight 0.3s ease-out forwards;
}

@keyframes slideLeft {
	0% {
		transform: translateX(0);
		opacity: 1;
	}
	100% {
		transform: translateX(-100%);
		opacity: 0;
	}
}

@keyframes slideRight {
	0% {
		transform: translateX(0);
		opacity: 1;
	}
	100% {
		transform: translateX(100%);
		opacity: 0;
	}
}

@media (hover: none) and (pointer: coarse) {
	.expert__card {
		touch-action: pan-y pinch-zoom;
	}

	.progress-dot {
		width: 6px;
		height: 6px;
	}
}

/* Existing styles for other elements */
.expert__card__top {
	padding: clamp(12px, 2vw, 24px);
	flex-direction: row;
	flex-wrap: wrap;
	gap: clamp(12px, 2vw, 24px);
	display: flex;
	gap: 32px;
	align-items: center;
}

.expert__card__top__left img {
	width: clamp(64px, 12vw, 120px);
	height: clamp(64px, 12vw, 120px);
	border-radius: 12px;
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
	object-fit: cover;
}

.expert__card__top__right {
	flex: 1;
	display: flex;
	flex-direction: column;
}

.expert__header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
}

.expert__type {
	font-size: 15px;
	color: #666;
	font-weight: 500;
}

.reviews-item {
	display: inline-flex;
	align-items: center;
	gap: 8px;
	background: #eeeeeea6;
	padding: 8px 16px;
	border-radius: 8px;
	transition: transform 0.2s ease;
}

.reviews-item:hover {
	transform: translateY(-2px);
}

.stats-icon {
	color: #ffb400;
	font-size: 18px;
}

.expert__card__top__right h1 {
	font-size: clamp(20px, 4vw, 32px);
	font-weight: 700;
	letter-spacing: -1px;
	line-height: 1.1;
	margin: 4px 0;
}

.expert__card__top__right h4 {
	font-size: clamp(14px, 2vw, 18px);
	color: #555;
	font-weight: 500;
}

.expert__card__bottom {
	padding: clamp(8px, 2vw, 16px);
	gap: clamp(12px, 2vw, 24px);
	flex: 1;
	overflow-y: auto;
}

.description-section {
	max-width: 720px;
}

.description-section h3 {
	font-size: clamp(16px, 3vw, 20px);
	font-weight: 700;
	letter-spacing: -0.5px;
	margin-bottom: 8px;
	color: #1e1e1e;
}

.description-section p {
	font-size: clamp(14px, 2vw, 16px);
	font-weight: 400;
	color: #3b3b3bc2;
	line-height: 1.4;
	letter-spacing: -0.2px;
	max-width: 65ch;
}

.categories-container {
	display: flex;
	gap: 12px;
	align-items: center;
	flex-wrap: wrap;
	margin: 12px 0px;
}

.category-item {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	padding: 6px 12px;
	background: rgba(234, 234, 234, 0.663);
	color: #000;
	border-radius: 8px;
	font-size: clamp(12px, 2vw, 14px);
	font-weight: 500;
	transition: all 0.2s ease;
}

.category-splitter-dot {
	display: inline-block;
	color: #666;
	font-size: 20px;
	line-height: 1;
	opacity: 0.5;
}

.category-icon {
	font-size: 16px;
}

.reviews-item:hover .stats-icon {
	transform: scale(1.1);
	transition: transform 0.2s ease;
}

.category-item:hover .category-icon {
	transform: rotate(15deg);
	transition: transform 0.2s ease;
}

@media (max-width: 768px) {
	.expert-modal-overlay {
		padding: 16px;
	}

	.expert-modal {
		max-height: min(100dvh - 32px, 800px);
		width: min(100%, 600px);
		margin: auto;
		border-radius: 12px;
		padding: 0;
	}

	.expert__card {
		height: 100%;
		padding: 0;
		border-radius: 12px;
		margin: 0;
		box-shadow: none;
		border: none;
	}

	.expert__card__content {
		padding: 0 16px;
	}

	.expert__card__top {
		padding: 16px;
		gap: 8px;
		flex-shrink: 0;
	}

	.expert__card__top__left img {
		width: 64px;
		height: 64px;
	}

	.expert__card__top__right {
		display: flex;
		flex-direction: column;
		gap: 4px;
	}

	.expert__card__top__right h1 {
		font-size: 18px;
		margin: 0;
	}

	.expert__card__top__right h4 {
		font-size: 13px;
		margin: 0;
	}

	.expert__type {
		font-size: 12px;
	}

	.reviews-item {
		padding: 4px 8px;
		font-size: 11px;
	}

	.expert__card__bottom {
		padding: 8px 16px;
		gap: 8px;
		flex: 1;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
	}

	.description-section {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
	}

	.description-section h3 {
		font-size: 15px;
		margin-bottom: 4px;
		flex-shrink: 0;
	}

	.description-section p {
		font-size: 12px;
		line-height: 1.3;
		flex-grow: 1;
		margin: 0;
	}

	.categories-container {
		flex-shrink: 0;
		gap: 6px;
		padding: 4px 0;
	}

	.category-item {
		padding: 3px 8px;
		font-size: 11px;
	}

	.expert-buttons {
		padding: 8px 16px;
		gap: 6px;
		margin-top: 4px;
		flex-shrink: 0;
	}

	.expert-button,
	.expert-button-secondary {
		padding: 10px;
		font-size: 13px;
		min-height: 36px;
	}
}

/* Smaller mobile screens */
@media (max-width: 480px) {
	.expert-modal-overlay {
		padding: 0;
		width: 100vw;
		height: 100dvh;
	}

	.expert-modal {
		width: 100%;
		margin: 0px;
		height: 100dvh;
		max-height: 100dvh;
		border-radius: 0;
	}

	.expert__card {
		border-radius: 0;
	}

	.expert__card__content {
		padding: 0 12px;
	}
}

/* Extra small height screens */
@media (max-height: 667px) {
	.expert__card__top {
		padding: 12px 16px;
	}

	.expert__card__top__left img {
		width: 68px;
		height: 68px;
	}

	.expert__card__bottom {
		padding: 6px 16px;
		gap: 6px;
	}

	.description-section h3 {
		font-size: 14px;
	}

	.description-section p {
		font-size: 11px;
		line-height: 1.2;
	}

	.expert-buttons {
		padding: 6px 16px;
	}

	.expert-button,
	.expert-button-secondary {
		padding: 8px;
		min-height: 32px;
		font-size: 12px;
	}
}

@media (min-width: 769px) and (max-width: 1024px) {
	.experts-grid {
		grid-template-columns: 1fr;
	}
	.expert__card__top {
		padding: 24px;
	}
	.expert__card__top__left img {
		width: 180px;
		height: 180px;
	}
	.expert__card__top__right h1 {
		font-size: 40px;
	}
	.expert__card__bottom {
		padding: 0 28px 28px;
	}
}
@media (min-width: 1025px) {
	.expert-modal {
		padding: 0 24px;
	}
	.experts-grid {
		grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
	}
}
.expert-button {
	background: var(--primary-gradient);
	color: white;
	border: 4px solid #ffcb9a;
	padding: 16px 24px;
	font-size: 16px;
	font-weight: 500;
	border-radius: 4px;
	cursor: pointer;
	width: 100%;
}
.expert-buttons a {
	width: 50%;
}
.expert-buttons {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: clamp(8px, 2vw, 16px);
	flex-direction: row;
	padding: 16px 0 8px;
	margin-top: auto;
}
.expert-button-secondary {
	width: 50%;
	background: #eaeaea;
	border: 4px solid #e0e0e0d6;
	border-radius: 4px;
	font-size: 16px;
	font-weight: 500;
	cursor: pointer;
	color: #000000;
	padding: 16px 24px;
	position: relative;
}

.expert-button-secondary:disabled {
	background: #f0f0f0;
	border-color: #e0e0e0;
	color: #999;
	cursor: not-allowed;
	opacity: 0.8;
}

/* Tooltip styles */
.tooltip {
	position: absolute;
	bottom: calc(100% + 12px);
	left: 50%;
	transform: translateX(-50%);
	background: rgba(0, 0, 0, 0.85);
	color: white;
	padding: 8px 12px;
	border-radius: 6px;
	font-size: 13px;
	white-space: nowrap;
	pointer-events: none;
	opacity: 0;
	visibility: hidden;
	transition: all 0.2s ease;
	z-index: 100;
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
	backdrop-filter: blur(4px);
}

.tooltip::after {
	content: '';
	position: absolute;
	top: 100%;
	left: 50%;
	transform: translateX(-50%);
	border-width: 6px;
	border-style: solid;
	border-color: rgba(0, 0, 0, 0.85) transparent transparent transparent;
}

.expert-button-secondary:disabled:hover .tooltip {
	opacity: 1;
	visibility: visible;
	transform: translateX(-50%) translateY(-4px);
}

/* Add smooth transition for disabled state */
.expert-button-secondary {
	transition: all 0.2s ease;
}

/* Improve visual feedback for disabled state */
.expert-button-secondary:disabled:active {
	transform: none;
}

.expert-modal__header {
	background: white;
	padding: 24px;
	border-bottom: 1px solid #eaeaea;
}
.expert-modal__title {
	margin-bottom: 24px;
	position: static;
}
.expert-modal__title h2 {
	font-size: 32px;
	font-weight: 700;
	color: #1e1e1e;
	margin-bottom: 8px;
}
.expert-modal__title p {
	font-size: 16px;
	color: #666;
	max-width: 600px;
}
.expert-modal__filters {
	display: flex;
	flex-direction: column;
	gap: 16px;
	position: sticky;
	top: 0;
	background: white;
	padding: 16px 0;
	z-index: 10;
	border-bottom: 1px solid #eaeaea;
}
.search-bar {
	position: relative;
	max-width: 600px;
}
.search-bar input {
	width: 100%;
	padding: 16px 48px;
	border: 1px solid #eaeaea;
	border-radius: 12px;
	font-size: 16px;
	background: #f8f8f8;
	transition: all 0.2s ease;
}
.search-bar input:focus {
	background: white;
	border-color: var(--primary-color);
	box-shadow: 0 0 0 4px rgba(var(--primary-color-rgb), 0.1);
	outline: none;
}
.search-icon {
	position: absolute;
	left: 16px;
	top: 50%;
	transform: translateY(-50%);
	color: #666;
	font-size: 20px;
}
.clear-search {
	position: absolute;
	right: 16px;
	top: 50%;
	transform: translateY(-50%);
	background: none;
	border: none;
	color: #666;
	cursor: pointer;
	padding: 4px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.clear-search:hover {
	background: #eaeaea;
}
.filter-categories {
	display: flex;
	gap: 8px;
	flex-wrap: wrap;
}
.filter-button {
	display: flex;
	align-items: center;
	gap: 8px;
	padding: 12px 20px;
	border: 1px solid #eaeaea;
	border-radius: 40px;
	background: white;
	color: #666;
	font-size: 14px;
	font-weight: 500;
	cursor: pointer;
	transition: all 0.2s ease;
}
.filter-button:hover {
	background: #f8f8f8;
	border-color: #ddd;
}
.filter-button.active {
	background: var(--primary-gradient);
	color: white;
	border-color: transparent;
}
.filter-icon {
	font-size: 16px;
}
.no-results {
	text-align: center;
	padding: 48px;
	color: #666;
	background: #f8f8f8;
	border-radius: 12px;
	margin: 24px 0;
}
@media (max-width: 768px) {
	.expert-modal__header {
		padding: 16px;
	}

	.expert-modal__title h2 {
		font-size: 24px;
	}
	.filter-categories {
		gap: 8px;
	}
	.filter-button {
		padding: 8px 16px;
		font-size: 13px;
	}
	.expert-modal__filters {
		padding: 12px 0;
	}
}
.experts-grid {
	display: grid;
	gap: 32px;
	grid-template-columns: 1fr;
	width: 100%;
	padding: 24px 0;
}
@media (max-width: 768px) {
	.experts-grid {
		gap: 24px;
		padding: 16px 0;
	}
}
.expert_modal_headline_info {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 16px;
	padding: 0 24px;
	margin: 16px 0;
}

.expert_modal_headline_info h3 {
	font-size: 20px;
	font-weight: 400;
	color: #1e1e1e;
	white-space: nowrap;
}

.expert_modal_headline_info .splitter {
	flex: 1;
	height: 1px;
	background: linear-gradient(to right, transparent, #eaeaea 20%, #eaeaea 80%, transparent);
}

@media (max-width: 768px) {
	.expert_modal_headline_info {
		padding: 0 16px;
		gap: 12px;
	}

	.expert_modal_headline_info h3 {
		font-size: 18px;
	}
}

.experts-carousel {
	position: relative;
	width: 100%;
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
	padding: 12px;
	box-sizing: border-box;
}

.nav-button {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	background: white;
	border: 1px solid #eaeaea;
	border-radius: 50%;
	width: clamp(36px, 8vw, 48px);
	height: clamp(36px, 8vw, 48px);
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
	z-index: 10;
	box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
	opacity: 0.9;
	backdrop-filter: blur(4px);
}

.nav-button svg {
	font-size: 21px;
	transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.nav-button:hover {
	box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
}

.nav-button:hover svg {
	transform: scale(1.1);
}

.nav-button:active {
	transform: translateY(-50%) scale(0.95);
}

.nav-button.prev {
	left: 8px;
}

.nav-button.next {
	right: 8px;
}

@media (max-width: 768px) {
	.nav-button {
		width: 40px;
		height: 40px;
		top: 40%;
	}

	.nav-button.prev {
		left: -12px;
	}

	.nav-button.next {
		right: -12px;
	}
}

@keyframes slideIn {
	from {
		opacity: 0;
		transform: translateX(50px);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}

.slide-left {
	animation: slideLeft 0.3s ease-out forwards;
}

.slide-right {
	animation: slideRight 0.3s ease-out forwards;
}

@keyframes slideLeft {
	0% {
		opacity: 0;
		transform: translateX(50%);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes slideRight {
	0% {
		opacity: 0;
		transform: translateX(-50%);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

.slide-left,
.slide-right {
	animation-duration: 0.4s;
	animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	animation-fill-mode: both;
}

.expert__badge {
	position: absolute;
	top: -16px;
	right: -12px;
	background: rgba(255, 154, 107, 0.95);
	color: white;
	padding: 10px 20px;
	border-radius: 30px;
	font-weight: 500;
	font-size: 13px;
	box-shadow: 0 6px 20px rgba(255, 107, 107, 0.25);
	z-index: 2;
	display: flex;
	align-items: center;
	gap: 8px;
	backdrop-filter: blur(8px);
	border: 1px solid rgba(255, 255, 255, 0.2);
	white-space: nowrap;
	transform-origin: center right;
	animation: badgeAppear 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.expert__badge .badge-icon {
	font-size: 18px;
	color: #ffd700;
	filter: drop-shadow(0 2px 4px rgba(255, 215, 0, 0.3));
}

.badge-text {
	display: flex;
	align-items: center;
	gap: 6px;
	letter-spacing: -0.2px;
}

@keyframes badgeAppear {
	from {
		opacity: 0;
		transform: translateY(-10px) scale(0.95);
	}
	to {
		opacity: 1;
		transform: translateY(0) scale(1);
	}
}

.expert__badge:hover {
	transform: translateY(-2px);
	box-shadow: 0 8px 24px rgba(255, 107, 107, 0.3);
	transition: all 0.3s ease;
}

@keyframes slideOutLeft {
	0% {
		opacity: 1;
		transform: translateX(0);
	}
	100% {
		opacity: 0;
		transform: translateX(-50%);
	}
}

@keyframes slideOutRight {
	0% {
		opacity: 1;
		transform: translateX(0);
	}
	100% {
		opacity: 0;
		transform: translateX(50%);
	}
}

.slide-out-left {
	animation: slideOutLeft 0.3s ease-out forwards;
}

.slide-out-right {
	animation: slideOutRight 0.3s ease-out forwards;
}

.expert__card[data-animating='true'] {
	pointer-events: none;
}

@media (max-width: 480px) {
	.expert-modal {
		padding: 12px;
	}

	.expert__card__top {
		padding: 16px;
		flex-direction: column;
		text-align: center;
	}

	.expert__card__top__left {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.expert__card__top__left img {
		width: 120px;
		height: 120px;
	}

	.expert__card__top__right h1 {
		font-size: 24px;
	}

	.expert__header {
		flex-direction: column;
		align-items: center;
		gap: 8px;
	}

	.expert-buttons {
		flex-direction: column;
		gap: 12px;
	}

	.expert-buttons a,
	.expert-button,
	.expert-button-secondary {
		width: 100%;
	}

	.expert__badge {
		position: relative;
		top: 0;
		right: 0;
		width: fit-content;
		margin: 0 auto 16px;
	}
}

@media (min-width: 481px) and (max-width: 768px) {
	.expert__card__top__right h1 {
		font-size: 32px;
	}

	.expert__badge {
		right: 0;
	}
}

@media (max-width: 768px) {
	.experts-carousel {
		padding: 8px;
	}

	.nav-button {
		width: 36px;
		height: 36px;
	}

	.nav-button.prev {
		left: -4px;
	}

	.nav-button.next {
		right: -4px;
	}

	.categories-container {
		margin-top: 12px;
	}

	.category-item {
		padding: 6px 12px;
		font-size: 13px;
	}
}

.expert__card,
.expert__card__top,
.expert__card__bottom,
.expert-buttons,
.category-item {
	transition: all 0.3s ease-in-out;
}

.expert-button,
.expert-button-secondary {
	min-height: 48px;
	touch-action: manipulation;
}

.expert-modal-close {
	position: fixed;
	top: 24px;
	right: 24px;
	background: white;
	border: 1px solid #eaeaea;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	z-index: 1002;
	transition: all 0.2s ease;
}

.expert-modal-close:hover {
	background: #f5f5f5;
	transform: rotate(90deg);
}

.expert-modal-close svg {
	font-size: 24px;
	color: #666;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes slideUp {
	from {
		opacity: 0;
		transform: translateY(20px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@media (max-width: 768px) {
	.expert-modal-overlay {
		padding: 0;
	}
	.expert-modal {
		height: 100vh;
		width: 100vw;
		border-radius: 0;
		padding: 0;
	}
	.expert__card {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		border-radius: 0;
		box-shadow: none;
		margin: 0;
	}

	.expert-modal-close {
		top: 16px;
		right: 16px;
		width: 36px;
		height: 36px;
	}
}

@media (min-width: 1024px) {
	.expert-modal {
		padding: 40px;
	}
}

.expert-modal::-webkit-scrollbar {
	width: 8px;
}

.expert-modal::-webkit-scrollbar-track {
	background: #f1f1f1;
}

.expert-modal::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 4px;
}

@supports (padding: max(0px)) {
	.expert-modal-overlay {
		padding-left: max(20px, env(safe-area-inset-left));
		padding-right: max(20px, env(safe-area-inset-right));
		padding-bottom: max(20px, env(safe-area-inset-bottom));
	}
}

@media (max-height: 667px) {
	.expert__card__top {
		padding: 8px;
	}

	.expert__card__top__left img {
		width: 56px;
		height: 56px;
	}

	.expert__card__top__right h1 {
		font-size: 18px;
	}

	.expert__card__bottom {
		gap: 8px;
	}

	.description-section {
		margin-bottom: 8px;
	}

	.categories-container {
		gap: 6px;
	}
}

@media (max-height: 480px) and (orientation: landscape) {
	.expert__card__top {
		flex-direction: row;
		align-items: center;
	}

	.expert__card__bottom {
		flex-direction: row;
		flex-wrap: wrap;
	}

	.description-section {
		flex: 1 1 50%;
	}

	.categories-container {
		flex: 1 1 40%;
	}

	.expert-buttons {
		width: 100%;
		flex-direction: row;
	}
}

@media (max-width: 768px) {
	.expert-modal-overlay {
		padding: 0;
	}

	.expert-modal {
		height: 100vh;
		width: 100vw;
		border-radius: 0;
		padding: 0;
	}

	.expert__card {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		border-radius: 0;
		box-shadow: none;
		margin: 0;
	}

	.expert__card__top {
		padding: 16px;
		gap: 12px;
	}

	.expert__card__top__left img {
		width: 72px;
		height: 72px;
	}

	.expert__card__top__right h1 {
		font-size: 20px;
		margin: 4px 0;
	}

	.expert__card__top__right h4 {
		font-size: 14px;
	}

	.expert__type {
		font-size: 13px;
	}

	.reviews-item {
		padding: 4px 8px;
		font-size: 12px;
	}

	.expert__card__bottom {
		padding: 12px 16px;
		gap: 12px;
		flex: 1;
		overflow-y: auto;
	}

	.description-section h3 {
		font-size: 16px;
		margin-bottom: 6px;
	}

	.description-section p {
		font-size: 13px;
		line-height: 1.3;
	}

	.category-item {
		padding: 4px 8px;
		font-size: 12px;
	}

	.expert-buttons {
		gap: 8px;
		margin-top: auto;
	}

	.expert-button,
	.expert-button-secondary {
		padding: 12px;
		font-size: 14px;
		min-height: 40px;
	}

	.expert__badge {
		padding: 6px 12px;
		font-size: 11px;
		right: 8px;
	}

	.badge-icon {
		font-size: 14px;
	}
}

/* Extra small screens */
@media (max-height: 667px) {
	.expert__card__top {
		padding: 12px;
	}

	.expert__card__top__left img {
		width: 66px;
		height: 66px;
	}

	.expert__card__top__right h1 {
		font-size: 22px;
	}

	.expert__card__bottom {
		padding: 8px 12px;
		gap: 8px;
	}

	.description-section p {
		font-size: 12px;
		line-height: 1.2;
	}
}

@media (max-width: 769px) {
	.expert__badge {
		position: relative;
		top: 0;
		right: 0;
		margin: 16px auto 0px;
		transform: translateX(0);
	}
}

.input-section {
	max-width: 680px;
	height: 100%;
	margin: 0 auto;
	padding: clamp(24px, 5vw, 40px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: clamp(24px, 4vw, 40px);
	animation: fadeScale 0.4s cubic-bezier(0.16, 1, 0.3, 1);
}

.input-header {
	text-align: center;
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.input-header h2 {
	font-size: clamp(24px, 4vw, 36px);
	font-weight: 700;
	color: #0f172a;
	line-height: 1.2;
	letter-spacing: -0.02em;
}

.input-header p {
	font-size: clamp(14px, 2vw, 16px);
	color: #64748b;
	max-width: 540px;
	margin: 0 auto;
	line-height: 1.6;
}

.input-container {
	width: 100%;
	position: relative;
	background: white;
	border-radius: 24px;
	box-shadow: 0 4px 24px -8px rgba(0, 0, 0, 0.1);
	transition: all 0.2s ease;
}

.input-container:focus-within {
	box-shadow: 0 8px 32px -12px rgba(0, 0, 0, 0.15);
}

.experience-input {
	width: 100%;
	min-height: 180px;
	padding: clamp(20px, 3vw, 28px);
	background: transparent;
	border: 1px solid #e2e8f0;
	border-radius: 24px;
	font-size: 15px;
	line-height: 1.6;
	color: #334155;
	resize: none;
	transition: all 0.2s ease;
}

.experience-input:focus {
	outline: none;
	border-color: #94a3b8;
}

.input-footer {
	padding: 16px clamp(20px, 3vw, 28px);
	display: flex;
	align-items: center;
	gap: 36px;
	justify-content: space-between;
	border-top: 1px solid #f1f5f9;
}

.input-hint {
	font-size: 13px;
	color: #94a3b8;
	padding: 0px 16px 0px 0px;
}

.submit-button {
	gap: 8px;
	padding: 12px 24px;
	background: var(--primary-gradient);
	color: white;
	border: none;
	border-radius: 12px;
	max-width: 300px;
	font-size: 14px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	width: 100%;
	cursor: pointer;
	transition: all 0.2s ease;
}

.submit-button:hover {
	transform: translateY(-1px);
	box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.2);
}

.submit-icon {
	font-size: 16px;
}

/* Loading State */
.loading-state {
	min-height: 400px;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	border-radius: 24px;
	position: relative;
	overflow: hidden;
}

.loading-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 48px;
	width: 100%;
	padding: 40px;
	background: linear-gradient(to bottom, #ffffff, #f8fafc);
	border-radius: 24px;
	box-shadow: 0 8px 32px -12px rgba(0, 0, 0, 0.08);
}

.loading-icon-wrapper {
	position: relative;
	width: 80px;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.loading-icon {
	position: absolute;
	width: 100%;
	height: 100%;
	border: 3px solid #f3f4f6;
	border-top: 3px solid #ff9a6b;
	border-radius: 50%;
	animation: spin 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

.loading-icon::after {
	content: '';
	position: absolute;
	inset: -8px;
	border: 3px solid transparent;
	border-top: 3px solid rgba(255, 154, 107, 0.2);
	border-radius: 50%;
	animation: spin 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite reverse;
}

.loading-messages-container {
	position: relative;
	width: 100%;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.loading-message {
	position: absolute;
	background: #ffffff;
	padding: 24px 32px;
	border-radius: 16px;
	box-shadow: 0 4px 16px -4px rgba(0, 0, 0, 0.1);
	display: flex;
	align-items: center;
	gap: 16px;
	width: 100%;
	max-width: 700px;
	transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.loading-message.entering {
	opacity: 0;
	transform: translateY(20px);
}

.loading-message.visible {
	opacity: 1;
	transform: translateY(0);
}

.loading-message.exiting {
	opacity: 0;
	transform: translateY(-20px);
}

.loading-message svg {
	font-size: 24px;
	color: #ff9a6b;
	flex-shrink: 0;
}

.loading-message span {
	color: #334155;
	font-size: 15px;
	font-weight: 500;
	line-height: 1.4;
}

.loading-progress {
	display: flex;
	gap: 12px;
	margin-top: 32px;
}

.progress-dot {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: #e2e8f0;
	transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.progress-dot.active {
	background: #ff9a6b;
	transform: scale(1.2);
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

.loading-state::before {
	content: '';
	position: absolute;
	inset: 0;
	background: linear-gradient(120deg, transparent, rgba(255, 154, 107, 0.03), transparent);
	animation: gradientMove 2s linear infinite;
}

@keyframes gradientMove {
	from {
		transform: translateX(-100%);
	}
	to {
		transform: translateX(100%);
	}
}

@media (max-width: 768px) {
	.loading-state {
		min-height: 320px;
		padding: 24px;
	}

	.loading-content {
		gap: 32px;
		padding: 24px;
	}

	.loading-icon-wrapper {
		width: 60px;
		height: 60px;
	}

	.loading-messages-container {
		height: 100px;
	}

	.loading-message {
		padding: 20px;
		gap: 12px;
	}

	.loading-message svg {
		font-size: 20px;
	}

	.loading-message span {
		font-size: 14px;
	}

	.loading-progress {
		margin-top: 16px;
	}
}

.carousel-hint-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.75);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1002;
	backdrop-filter: blur(8px);
	animation: fadeIn 0.3s cubic-bezier(0.16, 1, 0.3, 1);
	border-radius: 16px;
}

.carousel-hint-content {
	background: white;
	padding: 28px;
	border-radius: 20px;
	box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
	text-align: center;
	max-width: min(280px, 90%);
	width: 90%;
	animation: scaleIn 0.4s cubic-bezier(0.16, 1, 0.3, 1);
}

.hint-phone-animation {
	margin: 0 auto 20px;
	width: 100px;
	height: 160px;
	position: relative;
}

.hint-phone-frame {
	width: 100%;
	height: 100%;
	border: 3px solid #333;
	border-radius: 24px;
	position: relative;
	overflow: hidden;
	background: #f8f8f8;
}

.hint-phone-content {
	position: absolute;
	width: 80px;
	height: 140px;
	background: #fff;
	border-radius: 16px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
	animation: swipeAnimation 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

.hint-text {
	font-size: 18px;
	color: #222;
	margin-bottom: 12px;
	font-weight: 500;
}

.hint-subtext {
	font-size: 16px;
	color: #555;
	opacity: 0.8;
}

.swipe-indicator {
	position: absolute;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: rgba(255, 154, 107, 0.2);
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	animation: pulseAnimation 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

.swipe-arrow {
	position: absolute;
	width: 24px;
	height: 2px;
	background: #ff9a6b;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.swipe-arrow::before,
.swipe-arrow::after {
	content: '';
	position: absolute;
	width: 8px;
	height: 2px;
	background: #ff9a6b;
	right: 0;
}

.swipe-arrow::before {
	transform: rotate(45deg) translate(0, -3px);
}

.swipe-arrow::after {
	transform: rotate(-45deg) translate(0, 3px);
}

@keyframes swipeAnimation {
	0%,
	100% {
		transform: translate(-50%, -50%);
	}
	50% {
		transform: translate(-80%, -50%);
	}
}

@keyframes pulseAnimation {
	0%,
	100% {
		transform: translate(-50%, -50%) scale(1);
		opacity: 0;
	}
	50% {
		transform: translate(-50%, -50%) scale(1.5);
		opacity: 1;
	}
}

@keyframes scaleIn {
	from {
		opacity: 0;
		transform: scale(0.95);
	}
	to {
		opacity: 1;
		transform: scale(1);
	}
}

@media (max-width: 768px) {
	.carousel-hint-content {
		padding: 20px;
		max-width: min(240px, 85%);
	}

	.hint-phone-animation {
		width: 80px;
		height: 140px;
		margin-bottom: 16px;
	}
}

.expert-reason-overlay {
	position: fixed;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1002;
	padding: 24px;
}

.expert-reason-popup {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transform-origin: center center;
	background: #fff;
	border-radius: 24px;
	padding: clamp(24px, 5vw, 32px);
	width: min(90%, 560px);
	max-height: min(85vh, 800px);
	overflow-y: auto;
	border: 4px solid #e5e7eb;
	box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
	z-index: 1003;
	will-change: transform;
	backface-visibility: hidden;
	perspective: 1000;
}

@keyframes popupScale {
	from {
		opacity: 0;
		transform: translate(-50%, -50%) scale(0.98);
	}
	to {
		opacity: 1;
		transform: translate(-50%, -50%) scale(1);
	}
}

.expert-reason-content {
	display: flex;
	flex-direction: column;
	gap: 28px;
}

.expert-reason-section {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.expert-reason-title {
	font-size: clamp(20px, 4vw, 24px);
	font-weight: 600;
	color: #1a1a1a;
	display: flex;
	align-items: center;
	gap: 12px;
	letter-spacing: -0.4px;
}

.reason-icon {
	color: #ff9a6b;
	font-size: 1.2em;
	flex-shrink: 0;
}

.expert-reason-text {
	font-size: clamp(15px, 3vw, 16px);
	line-height: 1.6;
	color: #4b5563;
	letter-spacing: -0.011em;
	margin: 0;
}

.expert-reason-list {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.expert-reason-list li {
	position: relative;
	padding-left: 28px;
	font-size: clamp(14px, 3vw, 15px);
	color: #4b5563;
	line-height: 1.5;
}

.expert-reason-list li::before {
	content: '•';
	position: absolute;
	left: 12px;
	color: #ff9a6b;
	font-size: 1.2em;
}

.expert-reason-divider {
	width: 100%;
	height: 1px;
	background: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.1) 15%, rgba(0, 0, 0, 0.1) 85%, transparent);
	margin: 4px 0;
}

.expert-reason-close {
	position: absolute;
	top: 8px;
	right: 8px;
	background: rgba(243, 244, 246, 0.9);
	border: none;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: all 0.2s ease;
	color: #6b7280;
	backdrop-filter: blur(4px);
	z-index: 3;
}

.expert-reason-close:hover {
	background: #e5e7eb;
	transform: rotate(90deg);
	color: #374151;
}

.expert-reason-close svg {
	font-size: 20px;
}

@keyframes popupScale {
	from {
		opacity: 0;
		transform: translateY(20px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

/* Scrollbar styling */
.expert-reason-popup::-webkit-scrollbar {
	width: 6px;
}

.expert-reason-popup::-webkit-scrollbar-track {
	background: transparent;
}

.expert-reason-popup::-webkit-scrollbar-thumb {
	background-color: #d1d5db;
	border-radius: 3px;
}

.expert-reason-popup::-webkit-scrollbar-thumb:hover {
	background: #9ca3af;
}

/* Mobile optimizations */
@media (max-width: 768px) {
	.expert-reason-overlay {
		padding: 0;
		width: 100vw;
		height: 100dvh;
	}

	.expert-reason-popup {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100dvh;
		max-height: 100dvh;
		margin: 0;
		border-radius: 0;
		transform: none;
		padding: 16px;
		display: flex;
		flex-direction: column;
	}

	.expert-reason-content {
		flex: 1;
		overflow-y: auto;
		padding: 0 0 16px 0;
	}

	.expert-reason-section {
		padding: 0;
	}

	.expert-reason-title {
		position: sticky;
		top: 0;
		background: white;
		padding: 16px 0;
		margin: -16px 0 0 0;
		z-index: 2;
	}

	.expert-reason-close {
		top: 8px;
		right: 8px;
		width: 40px;
		height: 40px;
		background: rgba(243, 244, 246, 0.9);
		backdrop-filter: blur(4px);
		z-index: 3;
	}
}

/* Small height screens */
@media (max-height: 600px) {
	.expert-reason-popup {
		max-height: 90vh;
		padding: 16px;
	}

	.expert-reason-content {
		gap: 16px;
	}
}

/* Handle safe areas for modern mobile devices */
@supports (padding: max(0px)) {
	@media (max-width: 768px) {
		.expert-reason-popup {
			padding-left: max(16px, env(safe-area-inset-left));
			padding-right: max(16px, env(safe-area-inset-right));
			padding-bottom: max(16px, env(safe-area-inset-bottom));
		}
	}
}

/* Improve scrollbar appearance */
.expert-reason-popup {
	scrollbar-width: thin;
	scrollbar-color: #d1d5db transparent;
}

.expert-reason-popup::-webkit-scrollbar {
	width: 6px;
}

.expert-reason-popup::-webkit-scrollbar-track {
	background: transparent;
}

.expert-reason-popup::-webkit-scrollbar-thumb {
	background-color: #d1d5db;
	border-radius: 3px;
}

/* Prevent body scroll when popup is open */
body.popup-open {
	overflow: hidden;
}

.hint-dismiss-text {
	font-size: 14px;
	color: #666;
	margin-top: 12px;
	font-style: italic;
}

.carousel-hint-overlay {
	cursor: pointer;
}

.carousel-hint-content {
	cursor: default;
}

.error-message-box {
	position: absolute;
	top: 20px;
	left: 50%;
	transform: translateX(-50%);
	background-color: #f8d7da;
	color: #721c24;
	padding: 16px;
	border-radius: 8px;
	border: 1px solid #f5c6cb;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	z-index: 1002;
	max-width: 90%;
	text-align: center;
	font-size: 16px;
}

.beta-badge {
	position: absolute;
	top: 10px;
	left: 10px;
	background-color: #6366f1;
	color: white;
	padding: 4px 12px;
	border-radius: 12px;
	font-size: 12px;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: 0.5px;
	box-shadow: 0 2px 4px rgba(99, 102, 241, 0.2);
}

.beta-disclaimer {
	font-size: 14px;
	color: #666;
	margin-top: 8px;
	font-style: italic;
}

.expert-modal-content {
	position: relative;
}

.like-button {
	background: none;
	border: none;
	padding: 8px;
	cursor: pointer;
	transition: transform 0.2s ease;
	display: flex;
	align-items: center;
	justify-content: center;
}

.like-button:hover {
	transform: scale(1.1);
}

.like-button .like-icon {
	font-size: 24px;
	color: #666;
	transition: all 0.2s ease;
}

.like-button.liked .like-icon {
	color: #ff4b4b;
	fill: #ff4b4b;
}

.like-button:hover .like-icon {
	color: #ff4b4b;
}

.expert__card__header {
	position: absolute;
	top: 16px;
	left: 16px;
	z-index: 2;
}

.like-button {
	display: flex;
	align-items: center;
	gap: 6px;
	background: rgba(255, 255, 255, 0.9);
	border: none;
	padding: 8px 12px;
	border-radius: 20px;
	cursor: pointer;
	backdrop-filter: blur(8px);
	transition: all 0.2s ease;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.like-button:hover {
	transform: scale(1.05);
	background: rgba(255, 255, 255, 1);
}

.like-button .like-icon {
	font-size: 18px;
	color: #666;
	transition: all 0.2s ease;
}

.like-button.liked .like-icon {
	color: #ff4b4b;
	fill: #ff4b4b;
	animation: likeAnimation 0.3s ease forwards;
}

.like-count {
	font-size: 14px;
	font-weight: 500;
	color: #444;
	min-width: 20px;
	text-align: left;
}

@keyframes likeAnimation {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.2);
	}
	100% {
		transform: scale(1);
	}
}

@media (max-width: 768px) {
	.expert__card__header {
		top: 12px;
		left: 12px;
	}

	.like-button {
		padding: 6px 10px;
	}

	.like-button .like-icon {
		font-size: 16px;
	}

	.like-count {
		font-size: 12px;
	}
}

.no-experts-banner {
	background: #fafafa;
	border: 1px solid #e0e0e0;
	border-radius: 6px;
	padding: 12px;
	margin: 12px 0;
	display: flex;
	align-items: center;
	gap: 10px;
	color: #666;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.4;
}

.no-experts-icon {
	font-size: 18px;
	color: #f49e53;
	flex-shrink: 0;
}

.experience-input:disabled {
	cursor: not-allowed;
	opacity: 0.8;
}

.submit-button:disabled {
	cursor: not-allowed;
	opacity: 0.8;
	transition: none;
}

.submit-button:disabled:hover {
	transform: none;
	box-shadow: none;
}
