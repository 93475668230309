/* Reset and base styles */
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

/* Wrapper for entire admin panel */
.admin-wrapper {
	display: flex;
	width: 100%;
	min-height: 100vh;
	background: #f3f4f6;
	overflow-x: hidden;
}

/* Main dashboard layout */
.admin-dashboard {
	display: flex;
	width: 100%;
	min-height: 100vh;
	position: relative;
}

/* Sidebar/Nav styles */
.dashboard-nav {
	width: 240px;
	min-width: 240px;
	height: 100vh;
	background: #fff;
	border-right: 1px solid #e5e7eb;
	position: fixed;
	left: 0;
	top: 0;
	overflow-y: auto;
	z-index: 40;
}

.nav-header {
	padding: 1.5rem;
	border-bottom: 1px solid #e5e7eb;
}

.nav-brand {
	display: flex;
	align-items: center;
}

.nav-title {
	font-size: 1.25rem;
	font-weight: 600;
	color: #111827;
}

.nav-menu {
	padding: 1rem;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.nav-item {
	display: flex;
	align-items: center;
	padding: 0.875rem 1rem;
	border-radius: 8px;
	color: #6b7280;
	cursor: pointer;
	transition: all 0.2s ease;
	user-select: none;
}

.nav-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
	margin-right: 12px;
}

.nav-label {
	font-size: 0.9375rem;
}

.nav-item:hover {
	background: #f3f4f6;
	color: #111827;
}

.nav-item.active {
	background: #4f46e5;
	color: white;
}

/* Main content area */
.dashboard-main {
	flex: 1;
	margin-left: 240px;
	padding: 2rem;
	background: #f3f4f6;
	min-height: 100vh;
	width: calc(100% - 240px);
}

.dashboard-content {
	width: 100%;
	max-width: 1400px;
	margin: 0 auto;
}

/* Mobile nav toggle button */
.mobile-nav-toggle {
	display: none;
	position: fixed;
	top: 1rem;
	right: 1rem;
	z-index: 50;
	padding: 0.5rem;
	background: white;
	border: 1px solid #e5e7eb;
	border-radius: 8px;
	cursor: pointer;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
	.dashboard-nav {
		transform: translateX(-100%);
		transition: transform 0.3s ease;
	}

	.dashboard-nav.active {
		transform: translateX(0);
	}

	.mobile-nav-toggle {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.dashboard-main {
		margin-left: 0;
		width: 100%;
		padding: 1.5rem;
	}

	.nav-overlay {
		display: none;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.5);
		z-index: 30;
	}

	.nav-overlay.active {
		display: block;
	}
}

@media (max-width: 768px) {
	.dashboard-main {
		padding: 1rem;
	}

	.admin-panel__stats {
		grid-template-columns: 1fr;
	}
}

/* Animations */
@keyframes fadeIn {
	from { opacity: 0; }
	to { opacity: 1; }
}

.nav-menu {
	animation: fadeIn 0.3s ease;
}

/* Stats cards grid */
.admin-panel__stats {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	gap: 1.5rem;
	width: 100%;
	margin-bottom: 2rem;
}

/* Individual stat card */
.stat-card {
	background: white;
	padding: 1.5rem;
	border-radius: 12px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
	display: flex;
	align-items: center;
	gap: 1rem;
	width: 100%;
	transition: transform 0.2s ease;
}

.stat-card:hover {
	transform: translateY(-2px);
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}
