.users-section {
	padding: 2rem;
	background: #f9fafb;
	min-height: calc(100vh - 64px);
}

.users-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 2rem;
	gap: 2rem;
}

.users-title {
	display: flex;
	align-items: center;
	gap: 1rem;
}

.users-icon {
	font-size: 1.5rem;
	color: #4f46e5;
}

.users-title h2 {
	font-size: 1.5rem;
	font-weight: 600;
	color: #111827;
	margin: 0;
}

.users-count {
	background: #e0e7ff;
	color: #4f46e5;
	padding: 0.25rem 0.75rem;
	border-radius: 9999px;
	font-size: 0.875rem;
	font-weight: 500;
}

.search-bar {
	position: relative;
	flex: 1;
	max-width: 400px;
}

.search-bar input {
	width: 100%;
	padding: 0.75rem 1rem 0.75rem 2.5rem;
	border: 1px solid #e5e7eb;
	border-radius: 8px;
	font-size: 0.875rem;
	transition: all 0.2s;
}

.search-bar input:focus {
	outline: none;
	border-color: #4f46e5;
	box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.1);
}

.search-icon {
	position: absolute;
	left: 0.75rem;
	top: 50%;
	transform: translateY(-50%);
	color: #9ca3af;
	font-size: 1.125rem;
}

.users-table-container {
	background: white;
	border-radius: 12px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
	overflow: hidden;
}

.users-table {
	width: 100%;
	border-collapse: collapse;
}

.users-table th {
	background: #f8fafc;
	padding: 1rem;
	text-align: left;
	font-weight: 500;
	color: #4b5563;
	border-bottom: 1px solid #e5e7eb;
	position: relative;
}

.user-row {
	transition: background-color 0.2s;
}

.user-row:hover {
	background-color: #f9fafb;
}

.user-row td {
	padding: 1rem;
	border-bottom: 1px solid #e5e7eb;
}

.user-info {
	display: flex;
	align-items: center;
	gap: 1rem;
}

.user-avatar {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	object-fit: cover;
	border: 2px solid #e5e7eb;
}

.user-details {
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
}

.user-name {
	font-weight: 500;
	color: #111827;
	margin: 0;
}

.user-email {
	font-size: 0.875rem;
	color: #6b7280;
	margin: 0;
}

.friends-count {
	background: #e0e7ff;
	color: #4f46e5;
	padding: 0.25rem 0.75rem;
	border-radius: 9999px;
	font-size: 0.875rem;
	font-weight: 500;
	display: inline-block;
	min-width: 2.5rem;
	text-align: center;
}

.action-buttons {
	display: flex;
	gap: 0.5rem;
	align-items: center;
}

.delete-button,
.blacklist-button {
	padding: 0.5rem;
	border: none;
	background: transparent;
	cursor: pointer;
	border-radius: 6px;
	transition: all 0.2s;
	display: flex;
	align-items: center;
	justify-content: center;
}

.delete-button {
	color: #ef4444;
}

.delete-button:hover {
	background: #fee2e2;
}

.blacklist-button {
	color: #6b7280;
}

.blacklist-button:hover {
	background: #f3f4f6;
	color: #4b5563;
}

.error-message {
	text-align: center;
	padding: 2rem;
	background: white;
	border-radius: 12px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
	color: #dc2626;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
}

.retry-button {
	padding: 0.5rem 1rem;
	background: #4f46e5;
	color: white;
	border: none;
	border-radius: 6px;
	cursor: pointer;
	font-size: 0.875rem;
	transition: background-color 0.2s;
}

.retry-button:hover {
	background: #4338ca;
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

/* Add these new styles for sorting */
.sortable {
	cursor: pointer;
	user-select: none;
	position: relative;
	padding-right: 2rem !important;
}

.sortable:hover {
	background-color: #f1f5f9;
}

.sort-icon {
	position: absolute;
	right: 0.75rem;
	top: 50%;
	transform: translateY(-50%);
	font-size: 1.25rem;
	transition: all 0.2s;
}

.sort-icon.inactive {
	opacity: 0.3;
}

.sort-icon.active {
	color: #4f46e5;
	opacity: 1;
}

.friends-count {
	background: #e0e7ff;
	color: #4f46e5;
	padding: 0.25rem 0.75rem;
	border-radius: 9999px;
	font-size: 0.875rem;
	font-weight: 500;
	display: inline-block;
	min-width: 2.5rem;
	text-align: center;
}

/* Update the table header styles */
.users-table th {
	background: #f8fafc;
	padding: 1rem;
	text-align: left;
	font-weight: 500;
	color: #4b5563;
	border-bottom: 1px solid #e5e7eb;
	position: relative;
}
