.blacklist-section {
    padding: 2rem;
    background: #f9fafb;
    min-height: calc(100vh - 64px);
}

.blacklist-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    gap: 2rem;
}

.title-section {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.blacklist-icon {
    font-size: 1.5rem;
    color: #dc2626;
}

.title-section h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #111827;
    margin: 0;
}

.blacklist-count {
    background: #fee2e2;
    color: #dc2626;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.875rem;
    font-weight: 500;
}

.blacklist-table-container {
    background: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.blacklist-table {
    width: 100%;
    border-collapse: collapse;
}

.blacklist-table th {
    background: #f8fafc;
    padding: 1rem;
    text-align: left;
    font-weight: 500;
    color: #4b5563;
    border-bottom: 1px solid #e5e7eb;
}

.blacklist-table td {
    padding: 1rem;
    border-bottom: 1px solid #e5e7eb;
}

.remove-button {
    padding: 0.5rem;
    border: none;
    background: transparent;
    color: #dc2626;
    cursor: pointer;
    border-radius: 6px;
    transition: all 0.2s;
}

.remove-button:hover {
    background-color: #fee2e2;
}

.loading, .error {
    text-align: center;
    padding: 2rem;
    color: #6b7280;
}

.error {
    color: #dc2626;
} 